import { template as template_24da0bd41211485984f754945fa21c16 } from "@ember/template-compiler";
const SidebarSectionMessage = template_24da0bd41211485984f754945fa21c16(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
