import { template as template_cac12c8ef9704188a60172e36d339c09 } from "@ember/template-compiler";
const FKControlMenuContainer = template_cac12c8ef9704188a60172e36d339c09(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
