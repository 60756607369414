import { template as template_2095fea0ade64ce7bf74a19344df6fed } from "@ember/template-compiler";
const WelcomeHeader = template_2095fea0ade64ce7bf74a19344df6fed(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
